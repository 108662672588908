/**
 * For units with complex shapes, move the labels for some units slightly in x and y so that the labels fall within
 * the bounds of the shape.
 */
const labelOffsets = {
  2550: [1, 12], // Swatch
  2700: [-4, 0], // H Samuel
  2950: [-2, 3], // Legends
  3000: [-2, -2], // Miniso
  3100: [2, 0], // Polestar
  3475: [4, 0], // ProCook
  4200: [4, 0], // Goldsmiths
  5275: [4, 0], // Superdrug
  5375: [-6, 0], // Sephora
  5500: [4, 7], // Sketchers
  6800: [-7, -3], // Pizza Hut
  6950: [-2, -2], // Costa
  7150: [2, 1], // TGI Fridays
  9950: [3, 3], // All Bar One
  10150: [-6, 3], // Thaikun
  10550: [-1, 1], // Pizza Express
  10750: [0, -2], // Coast to Coast
  10800: [1, 1], // Wagamama
  10850: [2, -2], // Est Est Est
  11600: [6, 0], // Vans
  11850: [6, 2], // Browns
  11900: [-4, 4], // Pandora
  12600: [-1, 1], // Boost (Upper-right)
  12800: [3, 0], // F. Hinds
  12950: [0, -7], // Tommy Hilfiger
  12700: [0, -4.5], // Scamp and Dude
  13250: [4, 0], // Office
};

export default labelOffsets;
