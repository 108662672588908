
import {useState, useEffect, useRef, useReducer} from "react";
import * as d3 from "d3";

import labelOrientations from "./labelOrientations";
import {toDataURL} from "../../js/svgUtils";
import {logEvent} from "../../js/interface";
import {dataOverlayColour} from "./dataOverlay";
import {offsetByFloor, getCenter} from "./mapUtils";
import { colours } from "../../theme";
import * as colourUtils from "../../js/colourUtils";
import { MAX_VALUE } from "../UnitSizeSelect/UnitSizeSelect";

import lowerFloorPlans from "../../images/lowerFloorPlans.png";
import upperFloorPlans from "../../images/upperFloorPlans.png";
import level5Plans from "../../images/level5Plans.png";

import Tooltip from "../Tooltip/Tooltip";
import ContextMenu from "../ContextMenu/ContextMenu";
import FloorSelect from "../FloorSelect/FloorSelect";
import ZoomControls from "../ZoomControls/ZoomControls";
import ScreenshotButton from "../ScreenshotButton/ScreenshotButton";


const width = 1190 //width of svg
const height = 560 //height of svg

const categoryCount = Object.keys(colourUtils.categoryLabeltoColourMap).length;

const kioskUnitIdMin = 50000 // All kiosk units have unit ids greater than 50000

const Map = ({
  unitsData,
  unitMap,
  breakOptionRange,
  notes,
  turnoverCalculatedValues,
  turnoverCalculatedValuesCategories,
  selectedUnit,
  otherUnits,
  leaseExpiryRange,
  handleClickUnit,
  selectedCategories,
  floor,
  setFloor,
  selectedUnitSize,
  dataOverlay,
  handleClickCompareUnit,
  isHighlightingUnits
}) => {

  const ref = useRef()
  const zoomInnerRef = useRef()  // pan and zoom transformations will happen on a different container to avoid complexity
  const zoomOuterRef = useRef()  // this runs the zoom functions
  const zoomInButtonRef = useRef()
  const zoomOutButtonRef = useRef()
  const zoomResetButtonRef = useRef()

  const optimiseSpeed = false;

  function reducer(state, action) {
    return { ...state, ...action }
  }

  // keep track of the last floor the user clicked so that we know which floor to zoom to in the case where there is
  // one unit id on multiple floors
  const [lastFloorSelected, setLastFloorSelected] = useState(0)
  const [lowerFloorPlansBase64, setLowerFloorPlansBase64] = useState(null)
  const [upperFloorPlansBase64, setUpperFloorPlansBase64] = useState(null)
  const [level5FloorPlansBase64, setLevel5FloorPlansBase64] = useState(null)

  // tooltip
  const [tooltipState, setTooltipState] = useReducer(reducer, {
    hidden: false,
    unitData: null,
    x: 500,
    y: 500,
    note: null,
  }, d => d)

  const drawLine = d3.line()
    .x(function(d) { return d[0]; })
    .y(function(d) { return d[1]; })
    .curve(d3.curveLinearClosed)

  /**
   * When user selects a floor, snap to that floor
   * @param floor_id integer floor number. Can be 0, 1, or 5.
   */
  const handleSelectFloor = (floor_id) => {
    handleClickUnit(null)
    removeDashedStroke()
    setFloor(floor_id)
    const zoomOuter = d3.select(zoomOuterRef.current)

    const offset = offsetByFloor(floor_id)

    zoomOuter
      .call(zoomOutButtonRef.zoom.translateTo, offset[0] + width/2, offset[1] + height/2)
      .call(zoomOutButtonRef.zoom.scaleTo, 1);

    logEvent({ eventName: "changeFloor", floor: floor_id })
  }

  // initial render - draw map
  // zoom functionality from this example: https://www.d3indepth.com/zoom-and-pan/
  useEffect(() => {

    const zoomInner = d3.select(zoomInnerRef.current)
    const zoomOuter = d3.select(zoomOuterRef.current)
    const handleZoom = (e) => {
      setTooltipState({ hidden: true })
      zoomInner
        .attr('transform', e.transform);
    }

    const zoom = d3.zoom()
      .scaleExtent([0.25, 10])
      .on('zoom', handleZoom);

    // save a reference to the zoom class for use in useEffects
    zoomOutButtonRef.zoom = zoom;

    zoomOuter
      .call(zoom)

    zoomInButtonRef.current.onclick = () => {
      zoomOuter
        .transition()
        .call(zoom.scaleBy, 2);
    }

    zoomOutButtonRef.current.onclick = () => {
      zoomOuter
        .transition()
        .call(zoom.scaleBy, 0.5);
    }

    zoomResetButtonRef.current.onclick = () => {
      setFloor(null)  // show all floors
      // center one floor
      // zoom.translateTo(zoomOuter, 0.5 * width, 0.5 * height)
      // zoom.scaleTo(zoomOuter, 1)

      // center two floors
      // zoom.translateTo(zoomOuter, 0, 0)
      // zoom.scaleTo(zoomOuter, 0.9)

      // center three floors
      zoom.translateTo(zoomOuter, 0.5 * width + 150, - 0.3 * height)
      zoom.scaleTo(zoomOuter, 0.5)

      logEvent({ eventName: "resetView" })
    }

    const svg = d3.select(ref.current)

    svg.html("");

    const p = 3 // pattern line spacing

    const pattern = svg.append("defs")
      .append("pattern")
        .attr("id", "red-stripe")
        .attr("width", p)
        .attr("height", p)
        .attr("patternUnits", "userSpaceOnUse")
        .attr("patternTransform", "rotate(45 50 50)")

    pattern
      .append("line")
        .attr("y1", 0)
        .attr("y2", 0)
        .attr("x1", 0)
        .attr("x2", p)
        .attr("stroke", colours.red)
        .attr("stroke-width", p)

    // move the tooltip
    zoomOuter
      .on("mousemove", (e, d) => {
        setTooltipState({ x: e.clientX, y: e.clientY });
      })
      .on("click", (e, d) => {
        handleClickUnit(null, floor)
        removeDashedStroke()
      })

    const innerUnits = svg.selectAll('g.inner')
      .data(unitMap)
      .enter()
      .append('g')
        .attr("class", "inner")

    innerUnits.each(function(d) {
      const group = d3.select(this);
      if (d.unit_id > kioskUnitIdMin) {
        group.append('circle')
            .attr("class", "inner")
          .attr("cx", getCenter(d.coords, d.unit_id)[0])
          .attr("cy", getCenter(d.coords, d.unit_id)[1])
          .attr("r", 2.125)
          .style("fill", colours.kiosk)
      } else {
        group.append('path')
          .attr("class", "inner")
          .attr("d", drawLine(d.coords))
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .style("fill", colours.pink)
      }
      group.selectAll("circle, path")
          .attr("id", d =>`${d.floor_id}-${d.zone_l1_id}-${d.zone_l2_id}-${d.unit_id}-${d.shape_id}`)
        .on("mouseout", (e, d) => {
            setTooltipState({ hidden: true });
          })
        .on("click", (e, d) => {
            e.stopPropagation();
            setLastFloorSelected(d.floor_id);
            removeDashedStroke();
            handleClickUnit(d.unit_id, d.floor_id);
          })
    });

    innerUnits
        .append("text")
        .attr("class", "label")
        .attr("fill", colours.black)

    innerUnits.selectAll("text")
      .attr("x", d => getCenter(d.coords, d.unit_id)[0])
      .attr("y", d => getCenter(d.coords, d.unit_id)[1])
      .attr("transform-origin", d => getCenter(d.coords, d.unit_id).join(" "))
      .attr("transform", d => labelOrientations[d.unit_id] ? `rotate(${labelOrientations[d.unit_id]})` : "")
      .attr("font-size", 4)
      .attr("text-anchor", "middle")
      .style("pointer-events", "none")
      .style("z-index", 900)

    // If returning from the Unit Comparison tab with a selected unit, trigger the auto-zoom.
    if (selectedUnit) {
      innerUnits.filter(d => {
        return selectedUnit === d.unit_id
      })
        .raise()
        .selectAll("path, circle")
        .call(selection => {
          // Timeout is neccessary to allow the map & background some time to load.
          setTimeout(() => {
            zoomToSelection(selection)
          }, 1000);
        })
    }
  }, [])

  // when map mounts, load background images and convert them to base 64 data instead of URLs. They must be base64 so
  // that they can be included when exporting the map as a screenshot
  // this is done after the initial render to reduce time to first render
  useEffect(() => {
    toDataURL(lowerFloorPlans).then(imageData => {
      setLowerFloorPlansBase64(imageData)
      toDataURL(upperFloorPlans).then(imageData => {
        setUpperFloorPlansBase64(imageData)
        toDataURL(level5Plans).then(imageData => {
          setLevel5FloorPlansBase64(imageData)
        })
      })
    })
  }, [])

  // define and re-define mouseenter when data changes
  useEffect(() => {
    if (!unitsData) {
      return;
    }
    const svg = d3.select(ref.current)
    const innerUnits = svg.selectAll('g.inner')

    // this event listener must be updated every time because it references unitsData and notes
    innerUnits
      .on("mouseenter", (e, d) => {
        let unitData = unitsData.find(dd => dd.unit_id === d.unit_id)
        // find a prioritised note about the moused over unit if one exists
        // note: notes will be empty if app is in external view mode
        const note = notes.find(dd => dd.unitId === d.unit_id && dd.highPriority && !dd.archived)
        if (unitData) {
          unitData.turnoverValues = turnoverCalculatedValues.find(dd => dd.unit_id === d.unit_id)
          unitData.turnoverValuesCategories = turnoverCalculatedValuesCategories.find(dd => dd.category === unitData.category)
        }
        setTooltipState({ hidden: false, unitData, note })
      })

  }, [unitsData, notes, turnoverCalculatedValues, turnoverCalculatedValuesCategories])

  const zoomToSelection = (selection) => {
    // Zoom out to show all units (if in highlight mode)
    if (isHighlightingUnits && !selection.empty()) {
      zoomToAllUnits();
      return
    }
    // figure out which one was clicked on before zooming to the wrong floor
    if (selection._groups.length > 1) {
      const currentFloorSelection = selection.filter(d => d.floor_id === lastFloorSelected)
      if (!currentFloorSelection.empty()) {  // don't zoom to units that aren't on the map
        zoomToUnit(currentFloorSelection.datum())
        return;
      }
      // else zoom to the first unit selected
    }
    if (!selection.empty()) {  // don't zoom to units that aren't on the map
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(selectedUnit))
      // Zoom level is higher for storage units as they're small.
      const isStorage = unitData?.unit_type?.toLowerCase() === "storage";
      const isKiosk = unitData?.unit_type?.toLowerCase() === "kiosk";
      zoomToUnit(selection.datum(), isStorage, isKiosk);
    }
  }

  const zoomToUnit = (datum, isStorage = false, isKiosk = false) => {
    const zoomOuter = d3.select(zoomOuterRef.current)

    const point = getCenter(datum.coords)

    zoomOuter
      .transition()
      .duration(800)
      .call(zoomOutButtonRef.zoom.translateTo, point[0], point[1] + (isStorage ? 50 : isKiosk ? 25 : 100))
      .transition()
      .call(zoomOutButtonRef.zoom.scaleTo, isStorage ? 3 : isKiosk ? 4 : 2);
  }

  const zoomToAllUnits = () => {
    const svg = d3.select(ref.current)
    const innerUnits = svg.selectAll('g.inner')
    const zoomOuter = d3.select(zoomOuterRef.current)

    innerUnits.filter(d => {
      return !! otherUnits.find(otherUnitId => otherUnitId === d.unit_id) || selectedUnit === d.unit_id
    })
      .selectAll("path, circle")
      .call(selection => {
        // Guard against empty units (e.g. the cinema)
        if (!selection.empty()) {          
          // Create a concatened array of coordinates for all of the highlighted units.
          const concatCoords = selection.data().flatMap(d => d.coords)
          // Find the centre point of all of them.
          const centerPoint = getCenter(concatCoords);
          // Zoom the map out far enough to show all of those units.
          zoomOuter
            .transition()
            .duration(800)
            .call(zoomOutButtonRef.zoom.translateTo, centerPoint[0], centerPoint[1] + 300) // No need to show floor 5
            .transition()
            .call(zoomOutButtonRef.zoom.scaleTo, 0.4)
          }
        })
  }

  // update unit fill colour when data changes
  useEffect(() => {
    if (!unitsData) {
      return;
    }

    const svg = d3.select(ref.current)

    const innerUnits = svg.selectAll('g.inner')

    // colour by category
    innerUnits.selectAll("path, circle")
      .transition().duration(400)
      .style("fill", d => {
        const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))
        if (!unitData) {
          return colours.null;
        } else {
          return dataOverlayColour(d, unitData, dataOverlay, turnoverCalculatedValues, turnoverCalculatedValuesCategories)
        }
      })
      .style("stroke", d => colourUtils.getStroke(d, unitsData, dataOverlay, selectedUnit, otherUnits))
      .style("stroke-width", d => colourUtils.getStrokeWidth(d, unitsData, dataOverlay, selectedUnit, otherUnits));

    // update labels
    innerUnits.selectAll("text.label, text.labelBorder")
      .text(d => {
        const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))
        if (!unitData) return "";
        if (!unitData.trading_as) return "";
        // Don't show labels for storage/kiosk units, as they're too cramped.
        if (unitData.category === "Storage") return "";
        if (unitData.category === "Kiosk") return "";
        return unitData.trading_as.replaceAll(" Ltd", "")
      });

  }, [unitsData, dataOverlay, turnoverCalculatedValues, otherUnits])

  // highlight the selected units
  useEffect(() => {
    removeDashedStroke();

    const svg = d3.select(ref.current)
    const innerUnits = svg.selectAll('g.inner')

    innerUnits.selectAll("path, circle")
      .style("stroke", d => colourUtils.getStroke(d, unitsData, dataOverlay, selectedUnit, otherUnits))
      .style("stroke-width", d => colourUtils.getStrokeWidth(d, unitsData, dataOverlay, selectedUnit, otherUnits))
      .style("display", d => colourUtils.getDisplay(d, unitsData, dataOverlay, selectedUnit, otherUnits, isHighlightingUnits))

    innerUnits.selectAll("text.label, text.labelBorder")
      .style("display", d => colourUtils.getDisplay(d, unitsData, dataOverlay, selectedUnit, otherUnits, isHighlightingUnits))

    // Raise the z-index of the other "trading_as" units as well.
    // Rasing them before the selected unit ensures that the selected unit is on top.
    if (otherUnits?.length) {
      innerUnits.filter(d => !! otherUnits.find(unitId => unitId === d.unit_id))
        .raise()
        .style("stroke-dasharray", 4) // Make the stroke around other units dashed.
    }

    innerUnits.filter(d => {
      return selectedUnit === d.unit_id
    })
      .raise()
      .selectAll("path, circle")
      .call(selection => {
        if (selectedUnit) {  // don't zoom on deselect
          zoomToSelection(selection);
        }
      })

  }, [selectedUnit, floor, otherUnits, isHighlightingUnits])

  // show hide units:
  // - when user filters on category, hide all unselected categories
  // - when user filters on selectedUnitSize, hide all units outside the range
  // - when user selects floor, hide the other floors
  useEffect(() => {
    const svg = d3.select(ref.current)
    const innerUnits = svg.selectAll('g.inner')
    const unitSizeFilter = (d, unitSize) => {
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))

      // logic: always show null units
      if (!unitData) return true;
      // logic: if we don't know the area of a unit, we always show it
      if (unitData.total_area === null || typeof(unitData.total_area) === "undefined" || unitData.total_area === "" || isNaN(unitData.total_area)) {
        return true
      } else {
        return unitData.total_area >= unitSize[0] && (unitSize[1] === MAX_VALUE || unitData.total_area <= unitSize[1])
      }
    }

    const leaseExpiryFilter = (d, leaseExpiryRange) => {
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id));

      // If no data for the unit or the lease_expiry is unknown, always show the unit.
      if (!unitData || !unitData.lease_expiry) {
        return true;
      }

      return (!leaseExpiryRange.startDate || new Date(unitData.lease_expiry) >= leaseExpiryRange.startDate)
        && (!leaseExpiryRange.endDate || new Date(unitData.lease_expiry) <= leaseExpiryRange.endDate)
    };

    const breakOptionFilter = (d, breakOptionRange) => {
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id));

      // If no data for the unit or the break_option is unknown then show the unit if there is no filter selection.
      if (!unitData || !unitData.break_option) {
        return !breakOptionRange.startDate || !breakOptionRange.endDate
      }

      return (!breakOptionRange.startDate || new Date(unitData.break_option) >= breakOptionRange.startDate)
        && (!breakOptionRange.endDate || new Date(unitData.break_option) <= breakOptionRange.endDate)
    };

    const categoryFilter = (d, selectedCategories) => {
      const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))
      if (selectedCategories.length === 0 || selectedCategories.length === categoryCount) {
        return !!unitData  // show all units with data
      } else {
        // if there's no data then only show it if 'Null' is selected
        if (!unitData) return selectedCategories.includes("Null");
        return selectedCategories.indexOf(unitData.category) !== -1
      }
    }

    const showOrHide = d => {
      if (floor !== null && d.floor_id !== floor) {
        return false
      } else {
        return categoryFilter(d, selectedCategories)
          && unitSizeFilter(d, selectedUnitSize)
          && leaseExpiryFilter(d, leaseExpiryRange)
          && breakOptionFilter(d, breakOptionRange)
      }
    }

    innerUnits
      .attr("transform", "")
      .attr("visibility", d => showOrHide(d) ? "visible" : "hidden")

  }, [selectedCategories, selectedUnitSize, floor, leaseExpiryRange, breakOptionRange])

  useEffect(() => {
    // start by centering the view - doesn't work without timeout for some reason
    setTimeout(() => {
      zoomResetButtonRef.current.onclick()
    }, 0)
  }, [])

  const removeDashedStroke = () => {
    // The stroke-dasharray property needs to be removed whenever the selection changes,
    // otherwise the units will have dashed lines even if they are un-selected.
    const svg = d3.select(ref.current);
    const innerUnits = svg.selectAll('g.inner');
    innerUnits.style("stroke-dasharray", undefined);
  }

  return (
    <div style={{ position: "relative" }}>
      <FloorSelect floor={floor} setFloor={handleSelectFloor} />
      <ZoomControls zoomInButtonRef={zoomInButtonRef} zoomResetButtonRef={zoomResetButtonRef} zoomOutButtonRef={zoomOutButtonRef} />
      <ScreenshotButton fileName={"map.png"} elementId={"svg#map"} />
      <Tooltip {...tooltipState} dataOverlay={dataOverlay} />
      <ContextMenu unitsData={unitsData} handleClickCompareUnit={handleClickCompareUnit}>
        <svg id={"map"} ref={zoomOuterRef} style={{ width: "calc(100vw - 220px)", height: "calc(100vh - 70px)", shapeRendering: optimiseSpeed ? "optimizeSpeed" : "", background: colours.background }} >
          <g ref={zoomInnerRef}>
            <image id={"lower-floor-plans"} href={lowerFloorPlansBase64} visibility={floor === null || floor === 0 ? "visible" : "hidden"} height={612} width={1185} transform={"translate(0, -13)"} />
            <image id={"upper-floor-plans"} href={upperFloorPlansBase64} visibility={floor === null || floor === 1 ? "visible" : "hidden"} height={612} width={1185} transform={"translate(260, -513)"} />
            <image id={"level-5-plans"} href={level5FloorPlansBase64} visibility={floor === null || floor === 5 ? "visible" : "hidden"} height={612} width={1185} transform={"translate(0, -1000)"} style={{ opacity: 0.75 }} />
            <g ref={ref} />
          </g>
        </svg>
      </ContextMenu>
    </div>
  )
}


export default Map;
