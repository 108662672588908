/**
 * Utilities for cleaning data after loading it
 */
import {demiseToCode} from "./format";

// need to subtract from all kiosk unit ids
const kioskUnitIdSubtraction = 50000

// TODO: move these calculations to the BigQuery report
const cleanLeasingScheduleRow = (row) => {
    row.building_id = row.bldg_id
    row.unit_id = parseInt(row.unit_id)
    row.rent = parseFloat(row.rent)
    row.turnover_rent_percentage = parseFloat(row.turnover_rent_percentage)
    row.last_year_turnover = parseFloat(row.last_year_turnover)
    row.total_area = parseFloat(row.total_area)
    row.lower_ground = parseFloat(row.lower_ground)
    row.lower_mezz = parseFloat(row.lower_mezz)
    row.upper_ground = parseFloat(row.upper_ground)
    row.upper_mezz = parseFloat(row.upper_mezz)
    row.occupancy_cost_ratio = parseFloat(row.occupancy_cost_ratio)

    // TODO: 'total_occupational_costs' is a slightly different thing in the leasing_schedule table; it doesn't include rent
    row.total_occupational_costs = row.rent + parseFloat(row.service_charge) + parseFloat(row.rates_payable)

    // calculate sales density if we have a value for turnover and area
    // note: this is only used in the 'Sales Density' overlay. The value in the unit details panel uses MAT Turnover
    let salesDensity = null
    if (!!row.last_year_turnover && !!row.total_area) {
      salesDensity = row.last_year_turnover / row.total_area
    }
    row.sales_density = salesDensity;

    // fill in null categories
    // note: if the tenant for a storage unit is the Trafford Centre, we are assuming that the unit is vacant
    if (row.category === "") {
      if (row.demise.toLowerCase().includes("atm")) {
        row.category = "ATM"
      }
      else if (row.building_id.includes("TRF003")) {
        row.category = "Kiosk"
        row.unit_type = "kiosk"
      }

      else if (row.demise.includes("Remote Store") || row.demise.includes("RemoteStore") || row.demise.includes("Storage") || row.demise.includes("Cold Store")) {
        // TODO: Data pipeline should probably add a new column that defines the unit type, e.g:
        // unit_type = 'retail' | 'storage' | 'kiosk' | etc...
        row.unit_type = "storage"
        if (row.tenant.includes("Trafford Centre")) {
            row.category = "Vacant";
        } else {
            row.category = "Storage";
        }
      }
      else {
        row.category = "Null"
      }
    }

    // Convert units with tenant as VACANT to The Trafford Centre and change category to vacant
    if (row.tenant.includes("VACANT")) {
        row.tenant = "The Trafford Centre Ltd"
        row.category = "Vacant"
    }

    row.unit_name = (row.category === "ATM") ? row.demise : demiseToCode(row.demise);

    // subtract 50000 from all kiosks so that their original unit ID is shown
    // this was required for the back-end modelling
    if (row.building_id.includes("TRF003")) {
        row.parsed_unit_id = row.unit_id - kioskUnitIdSubtraction
    } else {
        row.parsed_unit_id = row.unit_id
    }

    // rename columns for convenience and for continuity in the CSV export
    row.lease_expiry = row.end_date

    return row
}

const cleanMapRow = (d) => {
  return {
    id: `${d.unit_id}_${d.shape_id}`,
    unit_id: parseInt(d.unit_id),
    floor_id: parseInt(d.floor_id),
    shape_id: parseInt(d.shape_id),
    zone_l1_id: parseInt(d.zone_l1_id),
    zone_l2_id: parseInt(d.zone_l2_id),
    // Transform coords to correctly overlay Figma SVG with background images
    coords: JSON.parse(d.coords).map(point => [(parseFloat(point[0]) - 152.5) * 2, (parseFloat(point[1]) - 620) * 2]),
  }
}

export { cleanLeasingScheduleRow, cleanMapRow }